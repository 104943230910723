import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const Container = styled.section`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	width: 100%;
	& h3 {
		color: var(--blue);
		font-size: 48px;
		font-weight: 800;
		margin: 0;
		text-align: center;
	}
	& p {
		color: var(--brown);
		font-size: 18px;
		font-weight: 500;
		margin: 20px 0 30px 0;
		text-align: center;
	}
	& div {
		width: 250px;
		& a {
			align-items: center;
			background: var(--blue);
			border-radius: 8px;
			color: var(--white);
			display: flex;
			font-size: 18px;
			font-weight: 600;
			height: 48px;
			justify-content: center;
			width: 100%;
		}
	}
`;
const NotFound = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'not-found' });
	const { login } = useSelector(state => state);
	return (
		<Container>
			<h3>{t('title')}</h3>
			<p>{t('description')}</p>
			<div>
				{login ? (
					<Link to='/profile'>{t('my-profile')}</Link>
				) : (
					<Link to='/'>{t('login')}</Link>
				)}
			</div>
		</Container>
	);
};
export default NotFound;
