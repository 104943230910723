import { LOADING, LOGIN, STATUS, USER_UPDATE } from './Types';
const initialState = {
	loading: false,
	login: false,
	status: 'CHECKING',
	user: {
		avatar: '',
		balance: 0,
		birth_date: '',
		complete: '',
		first_name: '',
		gender: '',
		groups: [],
		id: '',
		last_name: '',
		login: '',
		region: {
			id: '',
			name_uz: '',
			name_ru: '',
		},
	},
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				login: action.login,
			};
		case LOADING:
			return {
				...state,
				loading: action.loading,
			};
		case STATUS:
			return {
				...state,
				status: action.status,
			};
		case USER_UPDATE:
			return {
				...state,
				user: action.user,
			};
		default:
			return state;
	}
};
export default reducer;
