import { array, object, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import Layout from './Layout';
import NoConnection from './NoConnection';
import NotFound from './NotFound';
import Waiting from './Waiting';
const Controller = ({ Component, type, ...rest }) => {
	const { login, status } = useSelector(state => state);
	return (
		<Route
			{...rest}
			render={props =>
				status === 'CHECKING' ? (
					<Waiting />
				) : status === 'CHECKED' ? (
					login && type === 'TEACHER' ? (
						<Layout>
							<Component {...props} />
						</Layout>
					) : !login && type === 'REGISTER' ? (
						<Component {...props} />
					) : (
						<NotFound />
					)
				) : (
					<NoConnection />
				)
			}
		/>
	);
};
Controller.propTypes = {
	Component: object || array,
	type: string.isRequired,
};
export default Controller;
