import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import NoInternet from '../Images/no-connection.svg';
const Container = styled.section`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	& img {
		width: 300px;
	}
	& h3 {
		max-width: 500px;
		text-align: center;
	}
	& button {
		background: var(--blue);
		border-radius: 8px;
		border: none;
		color: var(--white);
		font-size: 18px;
		font-weight: 600;
		height: 50px;
		outline: none;
		padding: 0 20px;
	}
`;
const NoConnection = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'app' });
	return (
		<Container className='container'>
			<img src={NoInternet} alt='internet connection lost' />
			<h3>{t('offline')}</h3>
			<button onClick={() => window.location.reload()}>{t('retry')}</button>
		</Container>
	);
};
export default NoConnection;
