import i18n from 'i18next';
import $ from 'jquery';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'rodal/lib/rodal.css';
import App from './App';
import './I18n';
import store from './Redux/Store';
import './style.css';
window.jQuery = $;
render(
	<Provider store={store}>
		<I18nextProvider i18n={i18n}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</I18nextProvider>
	</Provider>,
	document.getElementById('root'),
);
