import styled from 'styled-components';
export const Header = styled.header`
	background: var(--light-brown);
	border-bottom: 1px solid #e0e4ea;
	box-sizing: border-box;
	display: flex;
	height: 80px;
	padding: 0 calc(50% - 688px);
	position: sticky;
	top: 0;
	z-index: 99;
	@media (max-width: 1440px) {
		padding: 0 32px;
	}
	@media (max-width: 576px) {
		padding: 0 24px;
	}
	& .left {
		align-items: center;
		border-right: 1px solid #e0e4ea;
		display: flex;
		height: 100%;
		width: 272px;
		& img {
			height: 37px;
			&:nth-child(1) {
				@media (max-width: 786px) {
					display: none;
				}
			}
			&:nth-child(2) {
				display: none;
				@media (max-width: 786px) {
					display: initial;
				}
			}
		}
		@media (max-width: 768px) {
			width: 82px;
			& img {
				margin: 0 0 0 11px;
			}
		}
	}
	& .right {
		align-items: center;
		display: flex;
		width: calc(100% - 272px);
		@media (max-width: 768px) {
			width: calc(100% - 82px);
		}
		& .search {
			align-items: center;
			display: flex;
			height: 100%;
			width: calc(100% - 225px);
			@media (max-width: 991px) {
				width: 100%;
			}
		}
		& .notifications {
			border-left: 1px solid #e0e4ea;
			height: 100%;
			width: 80px;
			& a {
				align-items: center;
				display: flex;
				height: 100%;
				justify-content: center;
				position: relative;
				width: 100%;
				& img {
					width: 18px;
				}
				& span {
					align-items: center;
					background: var(--red);
					border-radius: 12px;
					border: 2px solid var(--white);
					color: var(--white);
					display: flex;
					font-size: 12px;
					font-weight: 600;
					justify-content: center;
					padding: 1px 5px;
					position: absolute;
					right: 21px;
					top: 17px;
				}
			}
			@media (max-width: 991px) {
				display: none;
			}
		}
		& .language {
			height: 100%;
			@media (max-width: 991px) {
				display: none;
			}
		}
	}
`;
export const Section = styled.section`
	align-self: stretch;
	background: linear-gradient(
		90deg,
		var(--light-brown) calc(50% - 416px),
		var(--white) calc(50% - 416px)
	);
	display: flex;
	height: calc(100vh - 81px);
	overflow-y: scroll;
	padding: 0 calc(50% - 688px);
	width: 100%;
	@media (max-width: 1440px) {
		background: linear-gradient(
			90deg,
			var(--light-brown) 304px,
			var(--white) 304px
		);
		padding: 0 32px;
	}
	@media (max-width: 768px) {
		background: linear-gradient(
			90deg,
			var(--light-brown) 114px,
			var(--white) 114px
		);
	}
	@media (max-width: 576px) {
		background: linear-gradient(
			90deg,
			var(--light-brown) 106px,
			var(--white) 106px
		);
		padding: 0 24px;
	}
	& aside {
		align-self: flex-start;
		border-right: 1px solid #e0e4ea;
		min-height: 100%;
		position: sticky;
		top: 0;
		width: 272px;
		z-index: 2;
		@media (max-width: 768px) {
			width: 82px;
		}
		& ul {
			margin: 32px 0;
			padding: 0 24px 0 0;
			& li {
				margin: 16px 0;
				& a {
					align-items: center;
					border-radius: 12px;
					display: flex;
					height: 58px;
					padding: 0 16px;
					position: relative;
					transition: 400ms;
					width: 100%;
					@media (max-width: 768px) {
						justify-content: center;
						&:before {
							background: var(--blue);
							border-radius: 6px;
							color: var(--white);
							content: attr(data-title);
							font-size: 16px;
							left: 100%;
							opacity: 0;
							padding: 6px 8px;
							position: absolute;
							top: 13px;
							transform: translateX(10px);
							transition: 0.3s;
							visibility: hidden;
							white-space: nowrap;
						}
					}
					& .svg {
						height: 24px;
						margin: 0 14px 0 0;
						stroke: var(--brown);
						transition: 400ms;
						width: 24px;
						@media (max-width: 768px) {
							margin: 0;
						}
					}
					& .settings {
						fill: var(--brown);
						height: 25px;
						margin: 0 14px 0 2px;
						transition: 400ms;
						width: 25px;
						@media (max-width: 768px) {
							margin: 0;
						}
					}
					& .notifications {
						fill: var(--brown);
						height: 25px;
						margin: 0 14px 0 0;
						transition: 400ms;
						width: 25px;
						@media (max-width: 768px) {
							margin: 0;
						}
					}
					& span {
						color: var(--bold);
						font-size: 16px;
						font-weight: 600;
						transition: 400ms;
						@media (max-width: 768px) {
							display: none;
						}
					}
					&:hover {
						background: var(--white);
						&:before {
							opacity: 1;
							transform: translateX(0);
							visibility: visible;
						}
						& .svg {
							stroke: var(--blue);
						}
						& span {
							color: var(--blue);
						}
						& .settings {
							fill: var(--blue);
						}
					}
					&.active {
						background: var(--white);
						& .svg {
							stroke: var(--blue);
						}
						& .settings {
							fill: var(--blue);
						}
						& span {
							color: var(--blue);
						}
					}
				}
			}
		}
	}
	& main {
		height: calc(100vh - 80px);
		width: calc(100% - 272px);
		@media (max-width: 768px) {
			width: calc(100% - 82px);
		}
		& section {
			padding: 24px 0 24px 24px;
			min-height: calc(100% - 80px);
		}
		& footer {
			align-items: center;
			border-top: 1px solid #e0e4ea;
			display: flex;
			height: 80px;
			justify-content: center;
			padding: 24px;
			& h4 {
				color: var(--brown);
				font-size: 16px;
				font-weight: 700;
				text-align: center;
			}
		}
	}
`;
