import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'rodal';
import styled, { createGlobalStyle } from 'styled-components';
import Logo from './Images/open-telegram.svg';
const Styles = createGlobalStyle`
	body {
		overflow-y: hidden !important;
	}
`;
const Container = styled.div`
	& .rodal {
		align-items: center;
		backdrop-filter: blur(2px);
		background: rgba(0, 0, 0, 0.3);
		display: flex;
		justify-content: center;
		padding: 0 calc(50% - 280px);
		z-index: 99999;
	}
	& .rodal-dialog {
		border-radius: 24px;
		margin: 0;
		padding: 50px 30px 30px 30px;
		position: relative;
		@media screen and (max-width: 576px) {
			border-radius: 16px;
			padding: 30px 20px 20px 20px;
		}
		& .content {
			align-items: center;
			display: flex;
			& img {
				height: 80px;
				margin: 0 15px 0 0;
				width: 80px;
			}
			& p {
				color: var(--brown);
				font-size: 18px;
				line-height: 150%;
				@media screen and (max-width: 576px) {
					text-align: center;
				}
			}
			@media screen and (max-width: 576px) {
				flex-direction: column;
			}
		}
		& .open-channel {
			display: flex;
			justify-content: flex-end;
			padding: 10px;
			& a {
				align-items: center;
				background: #039be5;
				border-radius: 8px;
				color: var(--white);
				display: flex;
				font-size: 17px;
				font-weight: 600;
				height: 45px;
				justify-content: center;
				width: 150px;
				@media screen and (max-width: 576px) {
					width: 100%;
				}
			}
			@media screen and (max-width: 576px) {
				padding: 0;
			}
		}
	}
	& .rodal-close {
		background: var(--light-brown);
		border-radius: 10px;
		height: 30px;
		outline: none;
		padding: 8px;
		right: 20px;
		top: 20px;
		width: 30px;
		&:before,
		&:after {
			background: var(--brown);
			left: 6px;
			transition: 400ms;
			width: 60%;
		}
		&:hover {
			&:before,
			&:after {
				background: var(--bold);
			}
		}
	}
	& .rodal-mask {
		background: transparent;
	}
	& .rodal-slideDown-enter {
		animation-timing-function: ease-in-out;
	}
	& .rodal-close::before,
	& .rodal-close::after {
		border-radius: 0;
	}
	@media screen and (max-width: 576px) {
		& .rodal {
			padding: 0 10px;
		}
	}
`;
const getCookie = name => {
	const cname = name + '=';
	let ca = '';
	if (process.browser) {
		ca = document.cookie.split(';');
	}
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(cname) === 0) {
			return c.substring(cname.length, c.length);
		}
	}
	return '';
};
const setCookie = ({ name, value, days }) => {
	const day = new Date();
	day.setTime(day.getTime() + days * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + day.toUTCString();
	document.cookie = name + '=' + value + ';' + expires + ';path=/';
};
const Telegram = () => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);
	const [time, setTime] = useState(0);
	const onToggle = () => {
		setVisible(!visible);
		setCookie({ name: 'telegram', value: 'true', days: 7 });
	};
	useEffect(() => {
		const telegram = getCookie('telegram');
		if (telegram) {
			setVisible(false);
		} else {
			if (time > 10) {
				setVisible(true);
			}
		}
	}, [time]);
	useEffect(() => {
		const timer = setInterval(
			() => (time < 12 ? setTime(time + 1) : null),
			1000,
		);
		return function cleanup() {
			clearInterval(timer);
		};
	}, [time]);
	return (
		<Container>
			<Modal
				animation='slideDown'
				closeOnEsc={true}
				onClose={onToggle}
				visible={visible}
				customStyles={{
					height: 'auto',
					width: '100%',
				}}>
				<div className='content'>
					<img src={Logo} alt='telegram' />
					<p>{t('app.telegram-description')}</p>
				</div>
				<div className='open-channel'>
					<a rel='noreferrer' target='_blank' href='https://t.me/uzprep'>
						{t('app.telegram-subscribe')}
					</a>
				</div>
				{visible && <Styles />}
			</Modal>
		</Container>
	);
};
export default Telegram;
