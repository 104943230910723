import { lazy } from 'react';

const ResetPassword = lazy(() => import('./Register/ResetPassword'));
const SignIn = lazy(() => import('./Register/SignIn'));

const CourseCreate = lazy(() => import('./Teacher/CourseCreate'));
const CourseEdit = lazy(() => import('./Teacher/CourseEdit'));
const Courses = lazy(() => import('./Teacher/Courses'));
const CourseSections = lazy(() => import('./Teacher/CourseSections'));
const LessonEdit = lazy(() => import('./Teacher/LessonEdit'));
const NotFound = lazy(() => import('./Teacher/NotFound'));
const Profile = lazy(() => import('./Teacher/Profile'));
const Settings = lazy(() => import('./Teacher/Settings'));
const Statistics = lazy(() => import('./Teacher/Statistics'));
const Notifications = lazy(() => import('./Teacher/Notifications'));
const PAGES = [
	{ path: '/', Component: SignIn, exact: true, type: 'REGISTER' },
	{
		path: '/reset-password',
		Component: ResetPassword,
		exact: true,
		type: 'REGISTER',
	},

	{ path: '/profile', Component: Profile, exact: true, type: 'TEACHER' },
	{ path: '/courses', Component: Courses, exact: true, type: 'TEACHER' },
	{
		path: '/courses/create',
		Component: CourseCreate,
		exact: true,
		type: 'TEACHER',
	},
	{
		path: '/courses/edit/:courseId',
		Component: CourseEdit,
		exact: true,
		type: 'TEACHER',
	},
	{
		path: '/courses/edit/:courseId/sections',
		Component: CourseSections,
		exact: true,
		type: 'TEACHER',
	},
	{
		path: '/courses/edit/:courseId/sections/:lessonId',
		Component: LessonEdit,
		exact: true,
		type: 'TEACHER',
	},
	{ path: '/statistics', Component: Statistics, exact: true, type: 'TEACHER' },
	{ path: '/settings', Component: Settings, exact: true, type: 'TEACHER' },
	{
		path: '/notifications',
		Component: Notifications,
		exact: true,
		type: 'TEACHER',
	},
	{ path: '*', Component: NotFound, exact: true, type: 'NOT_FOUND' },
];
export default PAGES;
