import { LOADING, LOGIN, STATUS, USER_UPDATE } from './Types';
export const login = login => ({
	type: LOGIN,
	login,
});
export const loading = loading => ({
	type: LOADING,
	loading,
});
export const setStatus = status => ({
	type: STATUS,
	status,
});
export const changeUser = user => ({
	type: USER_UPDATE,
	user,
});
