import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Slide, toast, ToastContainer } from 'react-toastify';
import Controller from './Controller/Controller';
import Loading from './Loading';
import PAGES from './Pages';
import { changeUser, login, setStatus } from './Redux';
import Axios from './Services/Axios';
import { reactToastifyConfiguration } from './Services/Configurations';
import Telegram from './Telegram';
const App = () => {
	const { t } = useTranslation('translation', { keyPrefix: 'app' });
	const dispatch = useDispatch();
	const { warning, info } = toast;
	const { loading } = useSelector(state => state);
	useEffect(async () => {
		await dispatch(setStatus('CHECKING'));
		const token = localStorage.getItem('token');
		if (token) {
			Axios()
				.get('/get_user')
				.then(async response => {
					const status = response?.data?.status;
					const data = response?.data?.data;
					if (status === 1) {
						await dispatch(setStatus('CHECKED'));
						await dispatch(login(true));
						dispatch(changeUser(data));
					} else {
						dispatch(setStatus('CHECKED'));
						localStorage.removeItem('token');
					}
				})
				.catch(e => {
					const toJSON = e?.toJSON;
					if (typeof toJSON === 'function') {
						const status = e?.toJSON()?.status;
						if (status === 401) {
							localStorage.removeItem('token');
							dispatch(setStatus('CHECKED'));
						} else {
							dispatch(setStatus('CONNECTION_ERROR'));
						}
					} else {
						dispatch(setStatus('CONNECTION_ERROR'));
					}
				});
		} else {
			dispatch(setStatus('CHECKED'));
		}
	}, []);
	useEffect(() => {
		console.log(
			`%c${t('note')}`,
			'color: #f1c40f; font-size: 36px; font-weight: 600; font-family: Gilroy, sans-serif',
		);
		window.addEventListener('offline', () => warning(t('offline')));
		window.addEventListener('online', () => info(t('online')));
	}, []);
	return (
		<>
			<Suspense fallback={<span></span>}>
				<Switch>
					{PAGES.map((page, index) => (
						<Controller key={index} {...page} />
					))}
				</Switch>
			</Suspense>
			<Telegram />
			<section>
				<ToastContainer {...reactToastifyConfiguration} transition={Slide} />
			</section>
			{loading && <Loading />}
		</>
	);
};
export default App;
